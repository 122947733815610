import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import withLayout from "../../layout";
import SEO from "../../components/SEO.js";
import WeAreKrominHero from "../../components/Landings/we-are-kromin/WeAreKrominHero.js";
import {Heading, Paragraph} from "../../components/UI/Typography.js";
import Dash from "../../components/UI/Dash.js";
import styled, {ThemeProvider} from "styled-components";
import dark from "../../theme/dark.js";
import {Button} from "../../components/UI/Button.js";
import {useInView} from "react-intersection-observer";
import firstIcon from "../../images/landings/Funnel.json";
import secondIcon from "../../images/landings/Gear.json";
import thirdIcon from "../../images/landings/Magnet.json";
import team from "../../images/landings/Foto_landing.jpg";
import LottieController from "../../components/UI/LottieController.js";
import Image from "../../components/UI/Image.js";
import hubspot from "../../images/landings/HubSpot-certified-partner.png";
import facebook from "../../images/landings/Facebook marketing partner.png";
import google from "../../images/landings/google partner.png";
import useDimensions from "react-use-dimensions";
import TransparentDrop from "../../images/transparent_drop.png";
import DropBg from "../../components/UI/DropBg.js";
import DigitalTransformationGuideForm from "../../components/Landings/we-are-kromin/DigitalTransformationGuideForm.js";
import BusinessAssessment from "../../images/landings/guideIcons/Business Assessment.svg";
import ValueStream from "../../images/landings/guideIcons/Value Stream Mapping.svg";
import DocumentFlow from "../../images/landings/guideIcons/Document Flow Analysis.svg";
import WorkflowDesign from "../../images/landings/guideIcons/Workflow Design.svg";
import WorkflowStrategy from "../../images/landings/guideIcons/Workflow strategy.svg";
import SalesAutomation from "../../images/landings/guideIcons/Sales Automation.svg";
import PipelineManagement from "../../images/landings/guideIcons/Pipeline Management.svg";
import ContactManagement from "../../images/landings/guideIcons/Contact management.svg";
import DocumentManagement from "../../images/landings/guideIcons/Document management.svg";
import SalesAnalytics from "../../images/landings/guideIcons/Sales Analytics.svg";
import InboundMarketing from "../../images/landings/guideIcons/Inbound Marketing Automation.svg";
import OnlineAnalytics from "../../images/landings/guideIcons/Online Analytics Tracking.svg";
import LeadTracking from "../../images/landings/guideIcons/Lead Tracking and management.svg";
import CorporateVideo from "../../images/landings/guideIcons/Corporate Video.svg";
import ContentStrategy from "../../images/landings/guideIcons/Content Strategy.svg";
import arrow1 from "../../images/landings/arrow1.jpg";
import arrow2 from "../../images/landings/arrow2.jpg";
import arrow3 from "../../images/landings/arrow3.jpg";
import arrow1mobile from "../../images/landings/arrow1mobile.jpg";
import arrow2mobile from "../../images/landings/arrow2mobile.jpg";
import arrow3mobile from "../../images/landings/arrow3mobile.jpg";
import one from "../../images/landings/1.svg";
import two from "../../images/landings/2.svg";
import three from "../../images/landings/3.svg";

const FullWidthMessage = styled.div`
    width: 100%;
    display: flex;
    line-height: 135%;
    z-index:2;
    align-items: center;
    position:relative;
    height:${(props) => props.bigMessage ? '252px' : '100px'};
    font-weight: 300;
    font-size: 1.5rem;
    overflow:hidden;
    background-image:${(props) => props.gradient};
    color: #fff;
    h4{
        font-size: min(max(28px,2.8vw),36px);
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.875px;
    }
    button {
        width: 285px;
        height:50px;
        z-index:2;
    }
    @media (max-width:768px) {
        height: auto;
        padding: 40px 0;
        text-align:center;
        h4{
            line-height:30px;
            margin:0 auto;
            text-align:center;
        }
        button {
            margin: 20px auto 0;
        }
    }
`;

const ourClients = [
    'clients/automobile.png', 'clients/Acea.png', 'clients/Carinci.png', 'clients/Arken.png', 'clients/Maui.png'
]

const digitalTransformationGuide = ({intl}) => {
    const keywords = intl.formatMessage({id: 'landingB.meta_keywords'}).split(',') || [];
    const [iconOne, inViewOne] = useInView();
    const [iconTwo, inViewTwo] = useInView();
    const [iconThree, inViewThree] = useInView();
    const [ref, refSize] = useDimensions();

    const scrollDown = () => {
        //safe because this event is fired only when window obj is loaded
        return window?.document?.getElementById('landingBForm').scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <div id={"landingB"} ref={ref}>
            <SEO title={intl.formatMessage({id: 'landingB.meta_title'})}
                 description={intl.formatMessage({id: 'landingB.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>

            <WeAreKrominHero titleThin={<FormattedMessage id={"landingB.hero_title_thin"}/>}
                             title={<FormattedMessage id={"landingB.hero_title"}/>}
                             subtitle={<FormattedMessage id={"landingB.hero_subtitle"}/>}
                             cta={<FormattedMessage id={"landingB.hero_main_cta"}/>}
                             scrollCb={scrollDown} withIcons={false}
            />

            <div style={{paddingTop: 88}}>
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 col-12">
                            <div style={{maxWidth: 354}}>
                                <Heading level={"2"} margin={'0 0 24px 0'}>
                                    <FormattedMessage id={"landingB.we_make_difference"}/>
                                </Heading>
                            </div>
                            <Dash/>
                            <Paragraph>
                                <FormattedMessage id={"landingB.difference_one"}/>
                            </Paragraph>
                            <Paragraph>
                                <FormattedMessage id={"landingB.difference_two"}/>
                            </Paragraph>
                            <div className="row">
                                <div className="col-4">
                                    <img src={hubspot}
                                         width={refSize?.width > 560 ? '120px' : refSize?.width > 330 ? "100px" : "80px"}
                                         alt={'partner logo'}/>
                                </div>
                                <div className="col-4">
                                    <img src={facebook}
                                         width={refSize?.width > 560 ? '120px' : refSize?.width > 330 ? "100px" : "80px"}
                                         alt={'partner logo'}/>
                                </div>
                                <div className="col-4">
                                    <img src={google} style={{boxShadow: "rgb(0 0 0 / 8%) 5px 5px 33px -1px"}}
                                         width={refSize?.width > 560 ? '120px' : refSize?.width > 330 ? "100px" : "80px"}
                                         alt={'partner logo'}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 d-md-block d-none">
                            <Image filename={'landings/groppo_foto.png'} alt={"team"}/>
                        </div>
                        <div className="col-md-6 col-12 d-md-none d-block mt-5">
                            <Image filename={'landings/Foto_Team_2.jpg'} alt={"team"} className="border-radius-pic"/>
                        </div>
                    </div>
                </div>
            </div>

            {/*  brand area*/}
            <div className="landing-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <Heading level={"2"} margin={'0 0 24px 0'}>
                                <FormattedMessage id={"landingB.our_side"}/>
                            </Heading>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center">
                        {ourClients.map((image, index) => <div className="col-lg col-sm-4 col-4" key={index}>
                            <Image className="clients-logo" filename={image} key={index} alt={"clients"}/>
                        </div>)}
                    </div>

                </div>
            </div>

            <ThemeProvider theme={dark} colors={dark.colors}>
                <div className="landing-padding section-medium-dark">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2 text-center">
                                <Heading level={"2"} thinChild={1} margin={'0 0 24px 0'}>
                                    <FormattedMessage id={"landingB.digitalization_one"}/>
                                    <FormattedMessage id={"landingB.digitalization_two"}/>
                                </Heading>
                                <Dash margin={"0 auto"}/>
                                <Paragraph className="pr-1">
                                    <FormattedMessage id={"landingB.digitalization_message"}/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
            <FullWidthMessage bigMessage={true} gradient={'linear-gradient(180deg, #A944F5 0%, #9938F2 100%)'}
                              drop={TransparentDrop}>
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-7 col-12">
                            <h4 style={{marginBottom: 8,marginTop:0}}><FormattedMessage id={"landingB.title_cta_ban"}/></h4>
                            <small style={{fontSize: 18}}><FormattedMessage id={"landingB.message_cta_ban"}/></small>
                        </div>
                        <div className="col-lg-5 col-12 justify-content-md-end justify-content-center d-flex">
                            <FormattedMessage id={"landingB.main_cta_ban"}>
                                {(msg) => <span onClick={() => scrollDown()} className="link-no-decoration">
                                    <Button type={'white'} label={msg}
                                            style={{marginTop: 36}}/>
                                </span>}
                            </FormattedMessage>
                        </div>
                    </div>
                </div>
                <DropBg src={TransparentDrop} right={'-8%'} bottom={'-50%'} mRight={'-90%'} zIndex={'-1'}/>
            </FullWidthMessage>

            <div className="container">
                <div className="row d-flex align-items-center landing-padding">
                    <div className="col-md-6 col-12 order-md-1 order-2">
                        <div>
                            <Heading level={"2"} margin={'0 0 24px 0'}>
                                <FormattedMessage id={"landingB.bullet_one_title"}/>
                            </Heading>
                        </div>
                        <Dash/>
                        <Paragraph>
                            <FormattedMessage id={"landingB.bullet_one_paragraph_1"}/>
                        </Paragraph>
                        <Paragraph>
                            <FormattedMessage id={"landingB.bullet_one_paragraph_2"}/>
                        </Paragraph>
                        <div className="row mb-4">
                            <div className="col-md col-4 d-block text-center mb-3">
                                <img src={BusinessAssessment} alt={"icon"} width={56}/>
                                <span className="land-ico"> Business Assessment</span>
                            </div>
                            <div className="col-md col-4 d-block text-center mb-3">
                                <img src={ValueStream} alt={"icon"} width={56}/>
                                <span className="land-ico"> Value Stream Map </span>
                            </div>
                            <div className="col-md col-4 d-block text-center mb-3">
                                <img src={DocumentFlow} alt={"icon"} width={56}/>
                                <span className="land-ico"> Document Flow Analysis</span>
                            </div>
                            <div className="col-md col-6 d-block text-center mb-3">
                                <img src={WorkflowDesign} alt={"icon"} width={56}/>
                                <span className="land-ico"> Workflow Design</span>
                            </div>
                            <div className="col-md col-6 d-block text-center mb-3">
                                <img src={WorkflowStrategy} alt={"icon"} width={56}/>
                                <span className="land-ico"> Workflow Strategy</span>
                            </div>
                        </div>
                        <span onClick={() => scrollDown()}>
                            <Button type={'primary'} label={<FormattedMessage id={"landingB.bullet_cta_1"}/>}
                                    style={{marginTop: 36}}/>
                        </span>
                    </div>
                    <div className="col-md-6 col-12 justify-content-center order-md-2 order-1">
                        <div ref={iconOne}>
                            <LottieController lottieJSON={secondIcon} inView={inViewOne}
                                              height={'400px'}/>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{backgroundColor: "#F6F6FB"}} className="landing-padding-desk">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 col-12 justify-content-center">
                            <div ref={iconTwo}>
                                <LottieController lottieJSON={firstIcon} inView={inViewTwo}
                                                  height={'400px'}/>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <Heading level={"2"} margin={'0 0 24px 0'}>
                                <FormattedMessage id={"landingB.bullet_two_title"}/>
                            </Heading>
                            <Dash/>
                            <Paragraph>
                                <FormattedMessage id={"landingB.bullet_two_paragraph_1"}/>
                            </Paragraph>
                            <Paragraph>
                                <FormattedMessage id={"landingB.bullet_two_paragraph_2"}/>
                            </Paragraph>
                            <div className="row mb-4">
                                <div className="col-md col-4 d-block text-center mb-3">
                                    <img src={SalesAutomation} alt={"icon"} width={56}/>
                                    <span className="land-ico"> Sales Automation </span>
                                </div>
                                <div className="col-md col-4 d-block text-center mb-3">
                                    <img src={PipelineManagement} alt={"icon"} width={56}/>
                                    <span className="land-ico"> Pipeline Management </span>
                                </div>
                                <div className="col-md col-4 d-block text-center mb-3">
                                    <img src={ContactManagement} alt={"icon"} width={56}/>
                                    <span className="land-ico"> Contact Profile </span>
                                </div>
                                <div className="col-md col-6 d-block text-center mb-3">
                                    <img src={DocumentManagement} alt={"icon"} width={56}/>
                                    <span className="land-ico"> Document Management </span>
                                </div>
                                <div className="col-md col-6 d-block text-center mb-3">
                                    <img src={SalesAnalytics} alt={"icon"} width={56}/>
                                    <span className="land-ico"> Sales Analytics </span>
                                </div>
                            </div>
                            <span onClick={() => scrollDown()}>
                            <Button type={'primary'} label={<FormattedMessage id={"landingB.bullet_cta_2"}/>}
                                    style={{marginTop: 36}}/>
                        </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row d-flex align-items-center landing-padding ">
                    <div className="col-md-6 col-12 order-md-1 order-2">
                        <Heading level={"2"} margin={'0 0 24px 0'}>
                            <FormattedMessage id={"landingB.bullet_three_title"}/>
                        </Heading>
                        <Dash/>
                        <Paragraph>
                            <FormattedMessage id={"landingB.bullet_three_paragraph_1"}/>
                        </Paragraph>
                        <Paragraph>
                            <FormattedMessage id={"landingB.bullet_three_paragraph_2"}/>
                        </Paragraph>
                        <Paragraph>
                            <FormattedMessage id={"landingB.bullet_three_paragraph_3"}/>
                        </Paragraph>
                        <div className="row mb-4">
                            <div className="col-md col-4 d-block text-center mb-3">
                                <img src={InboundMarketing} alt={"icon"} width={56}/>
                                <span className="land-ico"> Imbound Marketing </span>
                            </div>
                            <div className="col-md col-4 d-block text-center mb-3">
                                <img src={OnlineAnalytics} alt={"icon"} width={56}/>
                                <span className="land-ico"> Online Analytics </span>
                            </div>
                            <div className="col-md col-4 d-block text-center mb-3">
                                <img src={LeadTracking} alt={"icon"} width={56}/>
                                <span className="land-ico">Lead Management </span>
                            </div>
                            <div className="col-md col-6 d-block text-center mb-3">
                                <img src={CorporateVideo} alt={"icon"} width={56}/>
                                <span className="land-ico"> Corporate Video</span>
                            </div>
                            <div className="col-md col-6 d-block text-center mb-3">
                                <img src={ContentStrategy} alt={"icon"} width={56}/>
                                <span className="land-ico"> Content Strategy</span>
                            </div>
                        </div>
                        <span onClick={() => scrollDown()}>
                            <Button type={'primary'} label={<FormattedMessage id={"landingB.bullet_cta_3"}/>}
                                    style={{marginTop: 36}}/>
                        </span>
                    </div>
                    <div className="col-md-6 col-12 justify-content-center order-md-2 order-1">
                        <div ref={iconThree}>
                            <LottieController lottieJSON={thirdIcon} inView={inViewThree}
                                              height={'400px'}/>
                        </div>
                    </div>
                </div>

                <div className="row d-flex align-items-center section-padding-bottom">
                    <div className="col-md-8 offset-md-2 col-12 mb-5 text-center">
                        <Heading level={"4"} margin={'0 0 24px 0'}>
                            <FormattedMessage id={"landingB.flow"}/>
                        </Heading>
                    </div>
                    <div className="col-md-4 col-12 d-flex d-md-block justify-content-center">
                        <div className="arrow-item" style={{background: refSize?.width > 768 ? `url("${arrow1}")` : `url("${arrow1mobile}")`}}>
                            <img src={one} alt={"icon"}/>
                            <h5> <FormattedMessage id={"landingB.flow_1"}/> </h5>
                            <ul>
                                <li> <FormattedMessage id={"landingB.flow_1_message_a"}/></li>
                                <li> <FormattedMessage id={"landingB.flow_1_message_b"}/></li>
                                <li> <FormattedMessage id={"landingB.flow_1_message_c"}/></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 col-12 d-flex d-md-block justify-content-center">
                        <div className="arrow-item center-arrow-mb" style={{background: refSize?.width > 768 ? `url("${arrow2}")` : `url("${arrow2mobile}")`}}>
                            <img src={two} alt={"icon"} />
                            <h5> <FormattedMessage id={"landingB.flow_2"}/> </h5>
                            <ul>
                                <li> <FormattedMessage id={"landingB.flow_2_message_a"}/></li>
                                <li> <FormattedMessage id={"landingB.flow_2_message_b"}/></li>
                                <li> <FormattedMessage id={"landingB.flow_2_message_c"}/></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 col-12 d-flex d-md-block justify-content-center">
                        <div className="arrow-item last-arrow-mb" style={{background: refSize?.width > 768 ? `url("${arrow3}")` : `url("${arrow3mobile}")`}}>
                            <img src={three} alt={"icon"}/>
                            <h5> <FormattedMessage id={"landingB.flow_3"}/> </h5>
                            <ul>
                                <li> <FormattedMessage id={"landingB.flow_3_message_a"}/></li>
                                <li> <FormattedMessage id={"landingB.flow_3_message_b"}/></li>
                                <li> <FormattedMessage id={"landingB.flow_3_message_c"}/></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <ThemeProvider theme={dark} colors={dark.colors}>
                <div className="landing-padding section-medium-dark" id={"landingBForm"}>
                    <div className="container">
                        <div className="row d-flex align-items-start">
                            <div className="col-md-5 col-12 text-sm-center landing-form">
                                <Heading level={"3"} margin={'0 0 24px 0'}>
                                    <FormattedMessage id={"landingB.form_title"}/>
                                </Heading>
                                <Paragraph customSize={16}>
                                    <FormattedMessage id={"landingB.form_message"}/>
                                </Paragraph>
                                <div className="mt-4 mb-4">
                                    <Image filename={"guide.png"} alt={"guide"} />
                                </div>
                            </div>
                            <div className="col-md-1 d-md-block d-none"/>
                            <div className="col-md-6 col-12">
                                <DigitalTransformationGuideForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>

        </div>
    );
};
const customProps = {
    localeKey: "landingB", // same as file name in src/i18n/translations/your-lang/index.js,
    isLanding: true
};

export default (withLayout(customProps))(injectIntl(digitalTransformationGuide));
