import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button} from "../../UI/Button.js";
import * as PropTypes from "prop-types";
const URL = "https://api.kromin.it/v1/request-lp";
import ErrorIcon from "../../../images/form-error-icon.inline.svg";
import SuccessIcon from "../../../images/form-success-icon.inline.svg";
import * as Yup from "yup";

const validationSchema = Yup.object({
    first_name: Yup.string().required(<FormattedMessage id={"contactus.error_required"}/>),
});

SuccessIcon.propTypes = {className: PropTypes.string};
const DigitalTransformationGuideForm = ({intl, formtype}) => {
    const { handleSubmit, register, setError, errors, reset, watch, formState: {isSubmitting}, setValue } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            email: '',
            password: ''
        },
        validationSchema
    })
    const [submitted, setSubmitted] = useState(false);
   /* const [interests, setInterests] = useState([]);*/

    const onSubmit = async (data) => {
        try {
            await fetch(URL, {
                method: "POST", mode: "cors", cache: "no-cache", body: JSON.stringify({...data, mode: 2}),
                headers: {"Content-type": "application/json; charset=UTF-8"}
            });
            reset();
            setValue('business_type', null);
            setSubmitted(true);
        } catch (error) {
            setError("submit", "submitError", `Contact form erro`);
        }
    };

    const onInterestsToggle = async (value) => {
        let newInterests = interests
        const index = newInterests.indexOf(value);
        if (index > -1) {
            newInterests.splice(index, 1);
        }else{
            newInterests.push(value)
        }
        setInterests(newInterests)
    }

    const showSubmitError = <p style={{color: "#cd4a4a", border: "1px solid #cd4a4a", fontSize: "13px", lineHeight: "17px",
        padding: "16px", background: "#cd4a4217", fontWeight: 600}} id={"sendError"}>
        <FormattedMessage id={"landingA.message_not_sent"}/>
    </p>

    const showConfirmationSent = (
        <p style={{color: "rgb(71 166 131)", border: "1px solid #47a683", fontSize: "13px", lineHeight: "17px",
            padding: "16px", background: "#54bd8e52", fontWeight: 600}} id={"sendCompleted"}>
            <FormattedMessage id={"landingA.message_sent"}/>
        </p>
    );

    return <>
        <form onSubmit={handleSubmit(onSubmit)} className={"form-transparent"}>

            {/*FIRST NAME*/}
            <input name="first_name" placeholder={intl.formatMessage({id: 'landingA.first_name'})}
                   className={`form-control mt-2 ${errors?.first_name ? "form-error" : "form-success"}`}
                   {...register('first_name')} required={true} disabled={isSubmitting}
            />

            {/*LAST NAME*/}
            <input name="last_name" placeholder={intl.formatMessage({id: 'landingA.last_name'})}
                   className={`form-control mt-2 ${errors?.last_name ? "form-error" : "form-success"}`}
                   {...register('last_name')} required={true} disabled={isSubmitting}
            />

            {/*BUSINESS NAME*/}
            <input name="business_name" placeholder={intl.formatMessage({id: 'landingA.business_name'})}
                   className={`form-control mt-2 ${errors?.business_name ? "form-error" : "form-success"}`}
                   ref={register} required={true} disabled={isSubmitting}
            />

            {/* EMAIL */}
            <input name="email" placeholder={intl.formatMessage({id: 'landingA.email'})} type={'email'}
                   className={`form-control mt-2 ${errors?.email ? "form-error" : "form-success"}`}
                   {...register('email')} required={true} disabled={isSubmitting}
            />

            {/* PHONE */}
            <input name="phone" placeholder={intl.formatMessage({id: 'landingA.phone'})}
                   className={`form-control mt-2 ${errors?.phone ? "form-error" : "form-success"}`}
                   {...register('phone')} required={true} disabled={isSubmitting}
            />

            {/*BUSINESS TYPE*/}
            <select name="business_type" className={`select-css mt-2 ${errors?.business_type ? "form-error" : "form-success"}`}
                    placeholder={intl.formatMessage({id: 'landingA.business_type'})}
                    {...register('business_type')} required={true} disabled={isSubmitting}
            >
                <option value="" disabled selected>Tipologia di business*</option>
                <option value="Ristrutturazione">Ristrutturazione</option>
                <option value="Educazione (corsi di formazione)">Educazione (corsi di formazione)</option>
                <option value="Studio Legale">Studio Legale</option>
                <option value="Travel Agency">Travel Agency</option>
                <option value="Servizi di assistenza tecnica">Servizi di assistenza tecnica</option>
                <option value="Traslochi">Traslochi</option>
                <option value=" Servizi di pulizie">Servizi di pulizie</option>
                <option value="Altro">Altro</option>
            </select>

            {/*WEBSITE*/}
            <input name="website" placeholder={intl.formatMessage({id: 'landingA.website'})} style={{borderBottomLeftRadius: "25px"}}
                   className={`form-control mt-2 ${errors?.website ? "form-error" : "form-success"}`}
                   {...register('website')} disabled={isSubmitting}
            />

            {/*Check multiple*/}
            {/*<div className="row mt-4">
                <div className="col-12 text-center">
                    <h5 className="mt-0" style={{color:"#fff"}}>Indicaci la tua area di interesse</h5>
                </div>
                <div className="col-md-6 col-12 mb-md-0 mb-2">
                    <label className="check-container"> Aumentare il traffico sul tuo sito
                        <input type="checkbox" name="check_1" disabled={isSubmitting} onChange={() => onInterestsToggle("Aumentare il traffico sul tuo sito")}/>
                        <span className="checkmark"/>
                    </label>
                    <label className="check-container "> Generare nuovi contatti
                        <input type="checkbox" name="check_2" disabled={isSubmitting} onChange={() => onInterestsToggle("Generare nuovi contatti")}/>
                        <span className="checkmark"/>
                    </label>
                    <label className="check-container"> Convertire i contatti in clienti
                        <input type="checkbox" name="check_3" disabled={isSubmitting} onChange={() => onInterestsToggle("Convertire i contatti in clienti")}/>
                        <span className="checkmark"/>
                    </label>
                    <label className="check-container "> Diffusione del Brand
                        <input type="checkbox" name="check_4" disabled={isSubmitting} onChange={() => onInterestsToggle("Diffusione del Brand")}/>
                        <span className="checkmark"/>
                    </label>
                    <label className="check-container"> Mantenere i clienti esistenti
                        <input type="checkbox" name="check_5" disabled={isSubmitting} onChange={() => onInterestsToggle("Mantenere i clienti esistenti")}/>
                        <span className="checkmark"/>
                    </label>
                </div>
                <div className="col-md-6 col-12">
                    <label className="check-container"> Re/Up-selling sui clienti esistenti
                        <input type="checkbox" name="check_6" disabled={isSubmitting} onChange={() => onInterestsToggle("Re/Up-selling sui clienti esistenti")}/>
                        <span className="checkmark"/>
                    </label>
                    <label className="check-container "> Semplificare il processo di lavoro
                        <input type="checkbox" name="check_7" disabled={isSubmitting} onChange={() => onInterestsToggle("Semplificare il processo di lavoro")}/>
                        <span className="checkmark"/>
                    </label>
                    <label className="check-container"> Migliorare il mio sistema di vendita
                        <input type="checkbox" name="check_8" disabled={isSubmitting} onChange={() => onInterestsToggle("Migliorare il mio sistema di vendita")}/>
                        <span className="checkmark"/>
                    </label>
                    <label className="check-container"> CRM integrato con il sito web
                        <input type="checkbox" name="check_9" disabled={isSubmitting} onChange={() => onInterestsToggle("CRM integrato con il sito web")}/>
                        <span className="checkmark"/>
                    </label>
                    <label className="check-container "> Altro
                        <input type="checkbox" name="check_10" disabled={isSubmitting} onChange={() => onInterestsToggle("Altro")}/>
                        <span className="checkmark"/>
                    </label>
                </div>
            </div>*/}

            {/* Check */}
            <label className="check-container mt-4" style={{maxWidth: 250}}> Accetto <a href="https://www.iubenda.com/privacy-policy/7814431" target="_blank" rel="noreferrer" className="termslink">l'informativa sulla privacy</a>
                <input type="checkbox" name="terms" placeholder={intl.formatMessage({id: 'contactus.form_email'})}
                       {...register('terms', {
                           required: <FormattedMessage id={"contactus.error_required"}/>,
                       })}
                       disabled={isSubmitting}/>
                <span className="checkmark"/>
            </label>
            {errors?.terms ? <ErrorIcon className="input-icon"/> : watch('terms') ?
                <SuccessIcon className="input-icon"/> : null}
            {errors?.terms && <span className="form-error-message">È necessario accettare i termini e condizioni</span>}

            <div className="d-flex justify-content-end mt-4">
                <FormattedMessage id={"landingB.send"}>
                    {(msg) => <Button disabled={isSubmitting} type={'primary'} label={msg} arrow={true} id={"leadFormSubmit2"} sending={isSubmitting}/>}
                </FormattedMessage>
            </div>

            {submitted && showConfirmationSent}
            {(errors && errors?.submit) && showSubmitError}
        </form>
    </>
};

export default injectIntl(DigitalTransformationGuideForm);
